import "./App.css";
import portrait from "./images/portrait.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

function App() {
  return (
    <>
      <div className="App">
        <header>
          <h1>Nate Smith, Ph.D.</h1>
          <h2>natesmith.io</h2>
        </header>
        <section>
          <article>
            <img src={portrait} alt="Nate's Portrait" />
            <p>Hi, I'm Nate.</p>
            <p>
              I'm a software engineer; I work mostly in enterprise web
              development.
            </p>
            <p>
              I've held a variety of roles. I've been a quality engineer, an engineering manager,
              head of cloud and quality engineering, and a technical program
              manager. A lot of the work I've done in recent years has revolved
              around improving CI/CD pipelines and automated testing. I'm
              currently a principal software engineer at{" "}
              <a href="http://www.care.com">Care.com</a>.
            </p>
            <p>
              When I'm not writing code, I'm reading, playing PC video games, flying my drone, hanging out
              with my family, or enjoying a good glass of wine. My wife and I
              are both major wine nerds; we got our{" "}
              <a href="https://www.wsetglobal.com/qualifications/wset-level-3-award-in-wines/">
                level 3 certification
              </a>{" "}
              from the
              <a href="https://www.wsetglobal.com/">
                {" "}
                Wine & Spirit Education Trust
              </a>{" "}
              a few years ago.
            </p>
            <p>
              {" "}
              <a href="https://scholar.google.com/citations?hl=en&user=nKFh0WsAAAAJ">
                I'm also a philosopher</a>, although I haven't published anything in quite some time. I stepped away from it shortly after I finished my PhD, but it's been a rekindled interest lately.
              {" "}
              </p>
              <p>
              I have degrees from Berkeley (BA Philosophy), the London School of Economics (MSc Philosophy and History of Science), and
              UC Davis (PhD Philsophy). My specialty is philosophy of science / philosophy of biology, but I've had bouts of interest in many areas, including ancient philosophy, early analytic philosophy, epistemology, applied ethics, and philosophy of law. I also never get tired of David Hume.
            </p>
          </article>
        </section>

        <footer>
          <div className="icon">
            <a href="https://www.linkedin.com/in/nate-smith-25568015/">
              <FontAwesomeIcon icon={faLinkedinIn} size={"2x"} />
            </a>
          </div>
          <div className="icon">
            <a href="https://github.com/nrxsmith">
              <FontAwesomeIcon icon={faGithub} size={"2x"} />
            </a>
          </div>
          <div className="icon">
            <a href="mailto:nate@natesmith.io">
              <FontAwesomeIcon icon={faEnvelope} size={"2x"} />
            </a>
          </div>
          <div className="icon">
            <a href="https://twitter.com/nrxsmith">
              <FontAwesomeIcon icon={faTwitter} size={"2x"} />
            </a>
          </div>
        </footer>
      </div>
    </>
  );
}

export default App;
